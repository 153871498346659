import { makeObservable, configure, observable, action } from "mobx";
import { axiosPublic } from "../modules/axios/axiosPublic";
import jwt from "jwt-decode";
import { axiosPrivate } from "../modules/axios/axiosPrivate";

import { getUserInfoByEmail } from "../api";

configure({ enforceActions: "observed" });

class Auth {
  rootStore = null;

  isSignIn = false;
  isSignUp = false;
  isReset = false;
  isChangePassword = false;
  isSuccefullRegistration = false;

  authUser = null;

  infoUser = null;

  userId = null;

  authError = "";

  successChangeUserInfo = false;

  statusActivate = "Происходит активация вашего аккаунта ...";

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.authUser = JSON.parse(localStorage.getItem("user")) || null;

    makeObservable(this, {
      isSignIn: observable,
      isSignUp: observable,
      isReset: observable,
      isChangePassword: observable,
      authError: observable,
      successChangeUserInfo: observable,
      authUser: observable,
      infoUser: observable,
      userId: observable,
      statusActivate: observable,
      isSuccefullRegistration: observable,

      changeSignIn: action,
      changeSingUp: action,
      checkPathForModal: action,
      registration: action,
      setAuthError: action,
      setSuccessChangeUserInfo: action,
      setSession: action,
      logout: action,
      setInfoUser: action,
      setUserId: action,
      activateAccount: action,
      setStatusActivate: action,
      setSuccefullRegistration: action,
      updateRoleAuthUser: action,
    });
  }

  async sendRequestForServer({ endpoint, data }) {
    try {
      const res = await axiosPublic.post(endpoint, data);

      const { error, message } = res.data;
      if (error) {
        return this.setAuthError(message);
      }

      return res;
    } catch (e) {
      this.setAuthError(e.response.data.message);
      this.setSession(null);
    }
  }
  jwtDecode(t) {
    return jwt(t);
  }

  changeSignIn(status) {
    this.isSignIn = status;
    this.setAuthError("");
  }
  changeSingUp(status) {
    this.isSignUp = status;
    this.setAuthError("");
  }

  changeReset(status) {
    this.isReset = status;
    this.setAuthError("");
  }

  changePassword(status) {
    this.isChangePassword = status;
  }

  checkPathForModal(pathname) {
    this.changeSignIn(pathname === "/signin");
    this.changeSingUp(pathname === "/signup");
    this.changeReset(pathname === "/forgot-password");
    this.changePassword(pathname.includes("/change-password"));
  }

  setAuthError(text) {
    this.authError = text;
  }

  setSuccessChangeUserInfo(text) {
    this.successChangeUserInfo = text;
  }

  setSession(data) {
    if (data) {
      const userInfo = this.jwtDecode(data.accessToken);

      this.authUser = userInfo;

      this.rootStore.Filter.setCurrentFilter();

      localStorage.setItem("session", JSON.stringify(data));
      localStorage.setItem("user", JSON.stringify(userInfo));
    } else {
      this.authUser = null;
      this.rootStore.Filter.setCurrentFilter();

      localStorage.removeItem("session");
      localStorage.removeItem("user");
    }

    this.rootStore.UIInterface.changeStateProUser();
  }

  setInfoUser(data) {
    this.infoUser = data;
  }

  setUserId(data) {
    this.userId = data;
  }

  async registration(data) {
    const endpoint = "/auth/register";
    const props = {
      email: data.email,
      password: data.password,
      firstName: data.name,
    };

    const res = await this.sendRequestForServer({ endpoint, data: props });

    if (res.data.error) {
      return this.setAuthError(res.data.message);
    }

    this.setSuccefullRegistration(true);
  }

  async userChangePassword(data) {
    const endpoint = "/auth/changeUserData";
    const props = {
      userId: this.userId,
      username: data.username,
      email: data.email,
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    const res = await this.sendRequestForServer({ endpoint, data: props });

    if (res.data.error) {
      return this.setAuthError(res.data.message);
    } else {
      return this.setSuccessChangeUserInfo(
        "Данные пользователя успешно обновлены",
      );
    }
  }

  setSuccefullRegistration(status) {
    this.isSuccefullRegistration = status;
  }
  async login(data) {
    const callback = (res) => {
      const {
        data: { data },
      } = res;

      this.setSession(data);
    };

    const endpoint = "/auth/login";
    const props = {
      email: data.email,
      password: data.password,
    };

    const res = await this.sendRequestForServer({ endpoint, data: props });
    callback(res);

    await this.getInFoForUser();
  }
  async reset(data) {
    const endpoint = "/auth/forgot-password";
    const props = {
      email: data.email,
    };
    const callback = () => {
      if (res?.data.data) {
        window.location.href = "/signin";
      }
    };
    const res = await this.sendRequestForServer({ endpoint, data: props });
    callback(res);
  }
  async confirmChangePassword(data, id) {
    const endpoint = `/auth/change-password`;
    const props = {
      password: data.password,
      refCode: id,
    };
    const callback = (res) => {
      if (res?.data.data) {
        window.location.href = "/signin";
      }
    };
    const res = await this.sendRequestForServer({ endpoint, data: props });
    callback(res);
  }
  async getInFoForUser() {
    try {
      if (!this.authUser) return;

      const data = await getUserInfoByEmail(this.authUser.sub);

      this.setInfoUser(data);

      this.setUserId(data.data.userId);

      await this.rootStore.Filter.setCurrentFilter(
        data.data?.userFilters || { default: false },
      );

      this.updateRoleAuthUser(data?.subscription?.role);

      return data;
    } catch (e) {
      console.error(e);
    }
  }

  updateRoleAuthUser(role) {
    if (this.authUser?.roles && role) {
      if (!this.authUser.roles.includes(role)) {
        const session = JSON.parse(localStorage.getItem("session"));
        if (session?.accessToken) {
          session.accessToken += "__no-actual";
          localStorage.setItem("session", JSON.stringify(session));
        }
      }

      this.authUser.roles = [role];
    }
  }

  async logout() {
    const endpoint = "/auth/logout";
    const props = {
      email: this.infoUser.data.email,
    };
    await axiosPrivate.post(endpoint, props);
    this.setSession(null);
  }

  async activateAccount(id) {
    try {
      const res = await axiosPublic.get(`/auth/activateAccount/${id}`);

      const { error, message } = res.data;
      if (error) {
        return this.setStatusActivate(message);
      }

      this.setStatusActivate("Поздравляем! Аккаунт активирован");
    } catch (e) {
      return this.setStatusActivate("Упс. Произошла ошибка");
    }
  }
  setStatusActivate(text) {
    this.statusActivate = text;
  }
}

export default Auth;
