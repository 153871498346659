const ButtonCardAction = ({ theme, handler, children, isDisabled }) => {
  const themes = {
    simple: "btn--theme-simple",
    favorite: "btn--theme-favorite",
    disabled: "btn--theme-disabled",
    selection: "btn--theme-selection-blue",
    dark: "btn--theme-dark",
  };

  const defaultTheme = "btn--theme-simple";
  if (!theme && isDisabled) theme = "disabled";
  if (theme === "disabled") isDisabled = true;

  return (
    <button
      className={`btn btn--size-md ${theme ? themes[theme] : defaultTheme} event-card__btn-list-item`}
      disabled={isDisabled}
      onClick={handler}
    >
      {children}
    </button>
  );
};

export default ButtonCardAction;
