import { observer } from "mobx-react-lite";
import { useApp } from "../../../hook/useApp";

const LogoutMessage = observer(() => {
  const { store } = useApp();

  const logoutMessage = store.UIInterface.logoutMessage;

  function closelogoutMessage() {
    store.UIInterface.removeLogoutMessage();
  }

  return (
    <div className="logout-message-block">
      <h3>Ошибка</h3>
      {logoutMessage}
      <div
        className="logout-message-block__close-button"
        onClick={closelogoutMessage}
      ></div>
    </div>
  );
});

export default LogoutMessage;
