import Main from "../../../components/layout/main/Main";
import Register from "../../../assets/public-pages/register.webp";

export const Rules = () => {
  return (
    <Main>
      <h1 className="main__title">Правила</h1>
      <h2>Как мне зарегистрироваться?</h2>
      <p>
        Для регистрации в нашем сервисе необходимо нажать кнопку "Регистрация".
        Далее нужно заполнить все необходимые поля регистрационной формы, нажать
        «Зарегистрироваться» и затем подтвердите свою регистрацию, перейдя по
        ссылке, которая придет на email.
      </p>
      <p>Форма регистрации представлена на изображении ниже:</p>
      <img src={Register} alt="" />
      <h2>Выбор тарифного пакета</h2>
      <p>
        Тарифный пакет необходимо выбрать после регистрации в личном кабинете.
      </p>
      <h2>Доступные тарифные пакеты:</h2>
      <p>Далее следует описание тарифов, контекст которых уже есть на сайте.</p>
    </Main>
  );
};
