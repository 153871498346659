import Main from "../../../components/layout/main/Main";

export const HowPlaceBet = () => {
  return (
    <Main>
      <h1 className="main__title">Как сделать ставку</h1>
      <p>
        Вилки в беттинге – это ставки в рамках одного события на противоположные
        исходы, что гарантирует игроку прибыль при любом итоге. Благодаря
        разнице в коэффициентах, которые устанавливают различные конторы, беттор
        может сделать несколько ставок, например, на победу первой команды,
        ничью и выигрыш второй команды таким образом, чтобы быть в плюсе при
        любом исходе. Это называется арбитражной ситуацией.
      </p>
      <p>
        Чтобы найти подходящие коэффициенты, игроку приходится анализировать
        линии сразу нескольких букмекеров, так как ни одна контора не допустит
        полную вилку на своем сайте. Поэтому такой способ заработка нельзя
        назвать легким, но задачу бетторам существенно упрощают вилочные
        сервисы.
      </p>
      <p>
        Сканер вилок – это специальная программа для автоматического поиска
        выигрышных арбитражных ситуаций. В Интернете таких сервисов огромное
        множество, и многие из них поддерживают русскоязычный интерфейс.
      </p>
      <p>
        Большинство вилочных программ доступны только за определенную плату.
        Можно найти несколько условно бесплатных сервисов, но версии из
        «свободного доступа» имеют сильно ограниченный функционал и ищут вилки с
        задержкой в несколько минут, что невыгодно для беттинга в лайве.
      </p>
    </Main>
  );
};
