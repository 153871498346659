export const InputCheckbox = ({ id, children, checked, onChange }) => {
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target)}
      />
      {children ? <label htmlFor={id}>{children}</label> : ""}
    </div>
  );
};
