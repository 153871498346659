import { useApp } from "../../hook/useApp";
import { observer } from "mobx-react-lite";

import Main from "../../components/layout/account/Main";
import ProfileLayout from "../../components/layout/account/ProfileLayout";

import Aside from "../../components/layout/account/aside/Aside";
import AsideHeader from "../../components/layout/account/aside/AsideHeader";
import AsideNavigation from "../../components/layout/account/aside/AsideNavigation";
import AsideMobileMenu from "../../components/layout/account/aside/AsideMobileMenu";

import ProfileTitle from "../../components/layout/account/ProfileTitle";

export const AccountPayments = observer(() => {
  const { store } = useApp();
  const isMobile = store.UIInterface.is800px;

  return (
    <Main>
      <ProfileLayout>
        <Aside>
          <AsideHeader />
          <AsideNavigation />
          {isMobile ? <AsideMobileMenu /> : ""}
        </Aside>
        {isMobile ? <ProfileTitle>Платежи</ProfileTitle> : ""}
      </ProfileLayout>
    </Main>
  );
});
