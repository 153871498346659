const Logo = ({ dark }) => {
  return (
    <a
      className={`logo ${dark ? "logo--accent-dark" : "logo--accent-white"} header__logo`}
      href="/"
    >
      <span className="logo__title">
        <span className="logo__title-text">Work</span>
        <span className="logo__title-text-accent">Bet</span>
      </span>
      {/* <span className="logo__subtitle">ваш гарант доходов на спорте</span> */}
    </a>
  );
};

export default Logo;
