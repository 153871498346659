import { Link } from "react-router-dom";

const FooterMenuItem = ({ url, children }) => {
  return (
    <li className="footer-nav__item">
      {url ? (
        <Link to={url} className="footer-nav__link">
          {children}
        </Link>
      ) : (
        children
      )}
    </li>
  );
};

export default FooterMenuItem;
