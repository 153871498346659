export const ForkTable = ({ children }) => {
  return <div className="event-card__table">{children}</div>;
};
export const ForkTableThead = ({ children }) => {
  return <div className="event-card__table-header">{children}</div>;
};
export const ForkTableBody = ({ children }) => {
  return <div className="event-card__table-body">{children}</div>;
};
export const ForkTableFooter = ({ children }) => {
  return <div className="event-card__table-footer">{children}</div>;
};
export const ForkTableRow = ({ visible = true, children }) => {
  if (!visible) return "";

  return <div className="event-card__table-tr">{children}</div>;
};
export const ForkTableRowInvisible = ({ visible = true, children }) => {
  if (!visible) return "";

  return <>{children}</>;
};
export const ForkTableCell = ({ size, addClass, children, visible = true }) => {
  if (!visible) return "";

  const sizeClass = size && size >= 0 ? ` event-card__cell--${size}` : "";

  return (
    <div className={`event-card__cell${sizeClass} ${addClass || ""}`}>
      {children}
    </div>
  );
};
