import { useApp } from "../../../../hook/useApp";
import { observer } from "mobx-react-lite";

import { IconAction } from "../../../ui/icons/Icons";

export const HeaderMenu = observer(({ children }) => {
  const { store } = useApp();

  const isMobile = store.UIInterface.isMobile;
  const isShowMenu = store.UIInterface.isShowMenu;

  if (isMobile && !isShowMenu) return "";

  return <div className="header__menu">{children}</div>;
});
export const HeaderMenuNavigation = ({ children }) => {
  const { store } = useApp();

  function closeBurgerMenu() {
    store.UIInterface.changeStateMenu();
  }

  return (
    <nav className="header-nav header__nav" role="navigation">
      <ul className="header-nav__list" onClick={closeBurgerMenu}>
        {children}
      </ul>
    </nav>
  );
};
export const HeaderMenuNavigationItem = ({ children, handler }) => {
  return (
    <li className="header-nav__item" onClick={handler}>
      {children}
    </li>
  );
};

export const HeaderBurgerMenuShow = () => {
  const { store } = useApp();

  function handleClick() {
    store.UIInterface.changeStateMenu();
  }

  return (
    <button
      className="lg-visible js-menu-toggle header__toggle"
      type="button"
      onClick={handleClick}
    >
      <span className="header__toggle-line"></span>
      <span className="header__toggle-line"></span>
      <span className="header__toggle-line"></span>
    </button>
  );
};
export const HeaderBurgerMenuHide = () => {
  const { store } = useApp();

  function handlerClick() {
    store.UIInterface.changeStateMenu();
  }

  return (
    <button
      className="js-menu-toggle header__menu-btn-close"
      type="button"
      onClick={handlerClick}
    >
      <IconAction name="close" />
    </button>
  );
};
