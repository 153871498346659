import Main from "../../../components/layout/main/Main";
import PaymentsImg from "../../../assets/public-pages/payments.webp";

export const Payments = () => {
  return (
    <Main>
      <h1 className="main__title">Платежи</h1>
      <p>
        Мы принимаем следующие способы оплаты: Visa, MasterCard, Skrill, ЮMoney,
        Neteller, Boleto, Giropay, Itaú, Мир, Przelewy24 и Сбербанк. Доступность
        способа оплаты зависит от страны плательщика и может меняться. Для
        оплаты выберите тариф и укажите период оплаты (мы предлагаем скидку при
        покупке подписки на длительный срок, а также чуть более дорогой тариф
        при покупке подписки на неделю).
      </p>
      <p>
        Вы можете платить в любой валюте, конвертация происходит автоматически.
        Из-за конвертации итоговая сумма может отличаться на несколько
        процентов. Свяжитесь с нами, если вам нужна дополнительная информация
        или другой способ оплаты.
      </p>
      <img src={PaymentsImg} alt="" />
    </Main>
  );
};
