import { makeObservable, configure, observable, action, computed } from "mobx";

import { checkIsProUser } from "../helpers/index";

import { axiosPublic } from "../modules/axios/axiosPublic";

configure({ enforceActions: "observed" });

class UIInterface {
  rootStore = null;

  isShowMenu = false;
  changeStateMenu() {
    this.isShowMenu = !this.isShowMenu;
  }

  isShowCalculator = false;
  changeStateCalculator() {
    this.isShowCalculator = !this.isShowCalculator;
  }

  isProUser = false;

  async changeStateProUser() {
    this.isProUser = checkIsProUser(this.rootStore.Auth.authUser);
  }

  windowWidth = window.innerWidth;
  get isMobile() {
    return this.windowWidth < 1025;
  }
  get is800px() {
    return this.windowWidth < 800;
  }
  changeWindowWidth(val) {
    this.windowWidth = val;
  }

  stateIntro = this.getIntroStatus();
  switchStateIntro() {
    this.stateIntro = !this.stateIntro;
    localStorage.setItem("IntroStatus", JSON.stringify(this.stateIntro));
  }
  getIntroStatus() {
    const defaultState = true;
    const localState = localStorage.getItem("IntroStatus");
    if (localState) {
      return JSON.parse(localState);
    }

    return defaultState;
  }

  listForkStatuses = {
    new: "is-new",
    favorite: "is-favorite",
    removed: "is-removed",
  };

  autoRefresh = true;
  changeStateAutoRefresh() {
    this.autoRefresh = !this.autoRefresh;
  }

  toast = null;
  setToast(payload) {
    this.toast = payload;
  }

  logoutMessage = null;
  setLogoutMessage() {
    this.logoutMessage =
      "Ваш логин и пароль для доступа в систему был использован на другом устройстве или в другом браузере. В связи с чем доступ на этом устройстве заблокирован";
  }

  removeLogoutMessage() {
    this.logoutMessage = null;
  }

  tooltipDelay = 1500;

  modalWriteToUse = {
    title: "Связаться с нами",
    visible: false,
    type: "Обращение",
    responseMessage: "",
    status: true,
  };
  changeStateModalWriteToUse({ type, value }) {
    this.modalWriteToUse[type] = value;
    if (type === "visible" && value) {
      this.modalWriteToUse.status = null;
    }
  }
  async sendMessageForServer(payload) {
    const res = await axiosPublic.post("/tech/writeToUse", {
      ...payload,
      messageType: this.modalWriteToUse.type,
    });

    const { error, message } = res.data;
    if (error) {
      this.changeStateModalWriteToUse({
        type: "responseMessage",
        value: message,
      });
      return false;
    }

    this.changeStateModalWriteToUse({ type: "responseMessage", value: "" });
    this.changeStateModalWriteToUse({ type: "status", value: true });

    return true;
  }

  constructor(rootStore) {
    makeObservable(this, {
      isShowMenu: observable,
      isShowCalculator: observable,
      isProUser: observable,
      windowWidth: observable,
      stateIntro: observable,
      listForkStatuses: observable,
      autoRefresh: observable,
      toast: observable,
      tooltipDelay: observable,
      modalWriteToUse: observable,
      logoutMessage: observable,

      isMobile: computed,
      is800px: computed,

      changeStateMenu: action,
      changeStateCalculator: action,
      changeWindowWidth: action,
      changeStateProUser: action,
      switchStateIntro: action,
      changeStateAutoRefresh: action,
      setToast: action,
      setLogoutMessage: action,
      removeLogoutMessage: action,
      changeStateModalWriteToUse: action,
      sendMessageForServer: action,
    });
    window.onresize = () => {
      this.changeWindowWidth(window.innerWidth);
    };
    this.rootStore = rootStore;

    this.changeStateProUser();
  }
}

export default UIInterface;
