import Intro from "../components/layout/intro/Intro";
import { HomeContent } from "../components/pages/home/HomeContent";
import { HomeContentHeader } from "../components/pages/home/HomeContentHeader";
import { ListForks } from "../components/pages/home/forks/Forks";
import { ModalWindowFilter } from "../components/layout/modalWindow/Filter/ModalWindowFilter";
import { useApp } from "../hook/useApp";

import { Tooltip } from "primereact/tooltip";

import { useRef, useEffect } from "react";

import { useSocket } from "../hook/useSocket";
import { observer } from "mobx-react-lite";

const Home = observer(() => {
  const tooltip = useRef(null);
  const { store } = useApp();
  const { onSocketOpen, onSocketClose } = useSocket();

  const isFilterModalWindow = store.Filter.modalWindow;
  const tooltipDelay = store.UIInterface.tooltipDelay;
  const isMobile = store.UIInterface.is800px;

  useEffect(() => {
    if (!isMobile) tooltip.current.updateTargetEvents();
  }, [
    store.SportEvent.stateChangeArray,
    store.UIInterface.typeTimeSport,
    store.UIInterface.isProUser,
    store.Auth.authUser,
  ]);

  useEffect(() => {
    if (store.Auth.userId) {
      const session = JSON.parse(localStorage.getItem("session"));
      onSocketOpen(`?token=${session?.accessToken}`);
    } else {
      onSocketClose();
    }
  }, [store.Auth.userId]);

  useEffect(() => {
    store.SportEvent.changeStateShowButtonForks(true);

    const uploadFilter = new Promise(async (resolve, reject) => {
      try {
        await store.Auth.getInFoForUser();
        resolve();
      } catch (e) {
        console.error(e);
        reject(e);
      }
    });

    uploadFilter.then(async () => {
      console.log("Фильтр загружен...");
    });
  }, [store.Auth.authUser]);

  useEffect(() => {
    store.SportEvent.getEventsFromServer();
  }, [store.Filter.currentFilter]);

  useEffect(() => {
    let interval = setInterval(() => {
      store.SportEvent.getStatistics();
    }, 3000);

    return () => clearInterval(interval);
  });

  return (
    <>
      <Intro store={store} />
      <HomeContent>
        <div className="container feed-section__container">
          <HomeContentHeader />
          <ListForks />
        </div>
      </HomeContent>
      {!isMobile && (
        <Tooltip
          ref={tooltip}
          target=".tooltip-show"
          position="top"
          showDelay={tooltipDelay}
        />
      )}
      <ModalWindowFilter visible={isFilterModalWindow} />
    </>
  );
});

export { Home };
