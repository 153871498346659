import { IconAction } from "../../../ui/icons/Icons";

const AsideMobileMenu = () => {
  return (
    <div className="profile-aside__header-btn-toggle">
      <IconAction name="toggle-aside-nav" />
    </div>
  );
};

export default AsideMobileMenu;
