import Main from "../../../components/layout/main/Main";

export const HowWithdrawMoney = () => {
  return (
    <Main>
      <h1 className="main__title">Как снять деньги</h1>
      <p>
        В большинстве случаем в букмекерских конторах для вывода денежных
        средств требуется обязательная верификация личности, путем
        предоставления фотокопий документов (паспорт, выписка с банка, квитанция
        по оплате ЖКХ с указанием Вашей прописки). При ее прохождении сможете
        выводить свои денежные средства.
      </p>
    </Main>
  );
};
