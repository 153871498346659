export const InputDecimal = (props) => {
  /* function handlerChange(e) {

        const position = e.target.selectionStart;

        let val = e.target.value;

        val = val.replace(/([^0-9.]+)/, "");

        val = val.replace(/^(0|\.)/, "");

        const match = /(\d{0,3})[^.]*((?:\.\d{0,2})?)/g.exec(val);

        const value = match[1] + match[2];

        e.target.value = value;

        if (val.length > 0) {
            e.target.value = Number(value).toFixed(2);
            e.target.setSelectionRange(position, position);
        } else {
            e.target.value = Number(0).toFixed(2);
        }
    } */

  function inputHandler(e) {
    const { target } = e;

    let value = target.value;
    if (value.includes(",")) {
      if (value.includes(".")) {
        value = value.replace(",", "");
      } else {
        value = value.replace(",", ".");
      }

      target.value = value;
    }

    const isValueIncludesDot = value.includes(".");
    if (!isValueIncludesDot) {
      if (value.length > 3) {
        target.value = value.substring(0, value.length - 1);
      }

      return;
    } else {
      if (value.length === 1) {
        target.value = "";
        return;
      }
    }

    const valueParts = value.split(".");
    if (valueParts.length !== 2) {
      return;
    }
    const isValidParts = valueParts.every((v) => {
      return v;
    });
    if (!isValidParts) {
      // try to change target value
      return;
    }
    const rightPart = valueParts[1];
    if (rightPart.length > 2) {
      valueParts[1] = rightPart.slice(0, 2);
    }

    target.value = valueParts.join(".");
  }

  function checkKey(e) {
    const regex = /^[0-9]*[.,]{0,1}$/;
    const { key, ctrlKey, keyCode, target, metaKey } = e;
    if (key === "ArrowUp") {
      return e.preventDefault();
    }
    if (key === "ArrowDown") {
      return e.preventDefault();
    }
    if (key === "Space") {
      return e.preventDefault();
    }
    const avaliableKeys = ["ArrowRight", "ArrowLeft", "Backspace", "Tab"];
    if (avaliableKeys.includes(key) || ctrlKey || metaKey) {
      return;
    }
    if (keyCode === 190 || keyCode === 191) {
      const { value } = target;
      if (value.includes(".")) {
        return e.preventDefault();
      }
    }
    const isValid = regex.test(key);
    if (!isValid) {
      e.preventDefault();
    }
  }

  return (
    <input
      type="text"
      onInput={inputHandler}
      onKeyDown={checkKey}
      /* onChange={handlerChange} */
      {...props}
    />
  );
};
