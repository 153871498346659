import { IconSocial } from "../../../ui/icons/Icons";

const FooterSocial = () => {
  return (
    <div className="contacts-media footer__contacts-media">
      <ul className="contacts-media__list">
        <li className="contacts-media__item">
          <a className="contacts-media__link" href="/">
            <IconSocial name="signal" />
          </a>
        </li>
        <li className="contacts-media__item">
          <a className="contacts-media__link" href="/">
            <IconSocial name="vk" />
          </a>
        </li>
        <li className="contacts-media__item">
          <a className="contacts-media__link" href="/">
            <IconSocial name="telegram" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
