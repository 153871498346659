import { makeObservable, configure, action } from "mobx";
import { axiosPrivate } from "../modules/axios/axiosPrivate";

configure({ enforceActions: "observed" });

class Payment {
  rootStore = null;

  constructor(rootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      setPayment: action,
    });
  }

  async sendRequestForServer({ endpoint, data }) {
    try {
      const res = await axiosPrivate.post(endpoint, data);

      const { error, message } = res.data;
      if (error) {
        return this.setAuthError(message);
      }

      return res.data;
    } catch (e) {
      console.error(e);
    }
  }

  async setPayment(payload) {
    try {
      const authUser = this.rootStore.Auth.authUser;
      if (!authUser) {
        return {
          ok: false,
          text: "Для оплаты тарифа необходимо авторизоваться или зарегистрироваться",
        };
      }

      let userInfo = this.rootStore.Auth.infoUser;
      if (!userInfo) {
        userInfo = await this.rootStore.Auth.getInFoForUser();
      }

      payload.userId = userInfo.data.userId;

      const data = await this.sendRequestForServer({
        endpoint: "pay/createPayment",
        data: payload,
      });
      const {
        data: { urlPayment },
      } = data;

      return { ok: true, url: urlPayment };
    } catch (e) {
      return { ok: false, text: "Упс, ошибочка" };
    }
  }
}

export default Payment;
