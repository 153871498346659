import { axiosPrivate } from "../modules/axios/axiosPrivate";
import { axiosPublic } from "../modules/axios/axiosPublic";

// API запроса вилок
export const getProfitableForks = async (filter) => {
  try {
    const response = await axiosPrivate.post("profitable_bets", filter);
    const { status, data } = response;

    if (status >= 200 && status < 300) return data;

    throw new Error("Error get profitable forks");
  } catch (e) {
    throw e;
  }
};
export const getProfitableForksNoAuth = async (filter) => {
  try {
    const response = await axiosPrivate.post("profitable_bets_no_auth", filter);
    const { status, data } = response;

    if (status >= 200 && status < 300) return data;

    throw new Error("Error get profitable forks for no auth user");
  } catch (e) {
    throw e;
  }
};
export const getForksByEventId = async (eventId, filter) => {
  try {
    const query = { ...filter, eventId };

    const response = await axiosPrivate.post("bets", query);
    const { status, data } = response;

    if (status >= 200 && status < 300) return data;

    throw new Error("Error get all forks by event");
  } catch (e) {
    throw e;
  }
};

export const getListBk = async () => {
  try {
    const response = await axiosPublic.get("listBk");
    const { status, data } = response;

    if (status >= 200 && status < 300) return data;

    throw new Error("Error get list bk");
  } catch (e) {
    throw e;
  }
};

export const writeToUse = async (payload) => {
  try {
    const response = await axiosPublic.post("tech/writeToUse", payload);
    const { status, data } = response;

    if (status >= 200 && status < 300) return data;

    throw new Error("Error write to use");
  } catch (e) {
    throw e;
  }
};

export const createPay = async (payload) => {
  try {
    const response = await axiosPrivate.post("pay/createPayment", payload);
    const { status, data } = response;

    if (status >= 200 && status < 300) return data;

    throw new Error("Error create pay");
  } catch (e) {
    throw e;
  }
};

export const saveFilterByUserId = async (userId, userFilters) => {
  try {
    const query = { userId, userFilters };

    const response = await axiosPrivate.post("auth/saveUserFilters", query);
    const { status, data } = response;

    if (status >= 200 && status < 300) return data;

    throw new Error("Error save filter by user id");
  } catch (e) {
    throw e;
  }
};

export const getUserInfoByEmail = async (email) => {
  try {
    const response = await axiosPrivate.get("auth/userInfo", {
      params: { email },
    });
    const { status, data } = response;

    if (status >= 200 && status < 300) return data;

    throw new Error("Error get user info by email");
  } catch (e) {
    throw e;
  }
};
