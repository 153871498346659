const ButtonProfit = ({ children, isActive, handler }) => {
  return (
    <button
      type="button"
      className={`btn btn--size-sm btn--theme-gray${
        isActive ? " is-active" : ""
      }`}
      onClick={handler}
    >
      {children}
    </button>
  );
};

export default ButtonProfit;
