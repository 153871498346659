import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { useApp } from "../../../../../hook/useApp";

const ProfileCardFormEdit = () => {
  const { store } = useApp();

  const changeError = store.Auth.authError ? (
    <p className="profile-settings-card__edit-input--w-100 p-error">
      {store.Auth.authError}
    </p>
  ) : (
    ""
  );

  const changeSuccess = store.Auth.successChangeUserInfo ? (
    <p className="profile-settings-card__edit-input--w-100 p-success">
      {store.Auth.successChangeUserInfo}
    </p>
  ) : (
    ""
  );

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.username) {
        errors.username = "Имя обязательно для заполнения";
      }

      if (!data.email) {
        errors.email = "E-mail обязателен для заполнения";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Некорректный e-mail. Пример: example@email.com";
      }

      if (data.oldPassword.length < 5) {
        errors.oldPassword = "Минимальная длина пароля 5 символов";
      }

      if (data.oldPassword.length > 30) {
        errors.oldPassword = "Максимальная длина пароля 30 символов";
      }

      if (!data.oldPassword) {
        errors.oldPassword = "Пароль обязателен для заполнения";
      }

      if (data.newPassword.length < 5) {
        errors.newPassword = "Минимальная длина пароля 5 символов";
      }

      if (data.newPassword.length > 30) {
        errors.newPassword = "Максимальная длина пароля 30 символов";
      }

      if (!data.newPassword) {
        errors.newPassword = "Пароль обязателен для заполнения";
      }

      if (!data.confirmPassword) {
        errors.confirmPassword = "Подтверждение пароля обязательное поле";
      }

      if (data.newPassword !== data.confirmPassword) {
        errors.confirmPassword = "Пароли не совпадают";
      }

      return errors;
    },
    onSubmit: async (data, actions) => {
      store.Auth.setAuthError("");
      store.Auth.setSuccessChangeUserInfo("");
      await store.Auth.userChangePassword(data);
      actions.resetForm();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <form
      className="profile-settings-card__edit"
      onSubmit={formik.handleSubmit}
    >
      <div className="input__wrapper profile-settings-card__edit-input--w-50">
        <input
          className={classNames({
            "p-error": isFormFieldValid("username"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true,
          })}
          type="text"
          name="username"
          placeholder="Имя"
          maxLength="35"
          value={formik.values.username}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("username")}
      </div>
      <div className="input__wrapper profile-settings-card__edit-input--w-50">
        <input
          className={classNames({
            "p-error": isFormFieldValid("email"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true,
          })}
          type="text"
          name="email"
          placeholder="E-mail"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("email")}
      </div>
      <div className="input__wrapper profile-settings-card__edit-input--w-50">
        <input
          className={classNames({
            "p-error": isFormFieldValid("newPassword"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true,
          })}
          type="password"
          name="newPassword"
          placeholder="Новый пароль"
          value={formik.values.newPassword}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("newPassword")}
      </div>
      <div className="input__wrapper profile-settings-card__edit-input--w-50">
        <input
          className={classNames({
            "p-error": isFormFieldValid("confirmPassword"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true,
          })}
          type="password"
          name="confirmPassword"
          placeholder="Подтверждение пароля"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("confirmPassword")}
      </div>
      <div className="input__wrapper input__wrapper--w-100">
        <input
          className={classNames({
            "p-error": isFormFieldValid("oldPassword"),
            "input-el input-el--size-md input-el--theme-white profile-settings-card__edit-input": true,
          })}
          type="password"
          name="oldPassword"
          placeholder="Для подтверждения новых изменений, введите текущий пароль"
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
        />
        {getFormErrorMessage("oldPassword")}
      </div>
      <button
        className="btn btn--theme-dark btn--size-st profile-settings-card__edit-btn"
        type="submit"
      >
        Обновить информацию
      </button>
      {changeError}
      {changeSuccess}
    </form>
  );
};

export default ProfileCardFormEdit;
