import styles from "../autorization.module.css";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { useApp } from "../../../hook/useApp";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";

import { Captcha } from "primereact/captcha";
import { useEffect } from "react";

const SignUp = observer(({ visible, handlerClose }) => {
  const navigate = useNavigate();

  const { store } = useApp();

  const authError = store.Auth.authError ? (
    <p className="p-error">{store.Auth.authError}</p>
  ) : (
    ""
  );
  const isSuccefullRegistration = store.Auth.isSuccefullRegistration;

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      acceptOne: false,
      acceptTwo: false,
      captcha: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.name) {
        errors.name = "Имя обязательно для заполнения";
      }

      if (data.name.length > 30) {
        errors.name = "Максимальная длина имени 30 символов";
      }

      if (!data.email) {
        errors.email = "E-mail обязателен для заполнения";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)
      ) {
        errors.email = "Некорректный e-mail. Пример: example@email.com";
      }

      if (data.password.length < 5) {
        errors.password = "Минимальная длина пароля 5 символов";
      }

      if (data.password.length > 30) {
        errors.password = "Максимальная длина пароля 30 символов";
      }

      if (!data.password) {
        errors.password = "Пароль обязателен для заполнения";
      }

      if (!data.confirmPassword) {
        errors.confirmPassword = "Подтверждение пароля обязательное поле";
      }

      if (data.password !== data.confirmPassword) {
        errors.confirmPassword = "Пароли не совпадают";
      }

      if (!data.acceptOne) {
        errors.acceptOne =
          "Необходимо согласится на обработку персональных данных";
      }

      if (!data.captcha) {
        errors.captcha = "Необходимо пройти капчу";
      }

      return errors;
    },
    onSubmit: async (data) => {
      store.Auth.setAuthError("");
      await store.Auth.registration(data);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const getFormInfoMessage = (name, message) => {
    return (
      !isFormFieldValid(name) && <small className="p-info">{message}</small>
    );
  };

  function showResponse(response) {
    formik.setFieldValue("captcha", response);
  }

  function resetCaptcha() {
    formik.setFieldValue("captcha", "");
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isSuccefullRegistration) {
        store.Auth.setSuccefullRegistration(false);
        navigate("/", { replace: false });
      }
      clearTimeout(timer);
    }, 5000);
  }, [isSuccefullRegistration]);

  if (!visible) return "";
  if (isSuccefullRegistration) {
    return (
      <div className={styles["modal-window"]}>
        <div
          className={styles["modal-window__close"]}
          onClick={handlerClose}
        ></div>
        <div className={styles["modal-window__title"]}>Информация</div>
        <p>
          Вы успешно зарегистрированы!
          <br />
          Для входа необходимо активировать аккаунт по ссылке, которая была
          отправлена, на указанный при регистрации, адрес электронной почты
        </p>
      </div>
    );
  }
  return (
    <div className={styles["modal-window"]}>
      <div
        className={styles["modal-window__close"]}
        onClick={handlerClose}
      ></div>
      <div className={styles["modal-window__title"]}>Регистрация</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles["modal-window_row"]}>
          <input
            type="text"
            placeholder="Имя"
            maxLength="30"
            className={classNames({ "p-error": isFormFieldValid("name") })}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {getFormInfoMessage("name", "Максимальная длина имени 30 символов")}
          {getFormErrorMessage("name")}
        </div>
        <div className={styles["modal-window_row"]}>
          <input
            type="text"
            placeholder="E-mail"
            className={classNames({ "p-error": isFormFieldValid("email") })}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("email")}
        </div>
        <div className={styles["modal-window_row"]}>
          <input
            type="password"
            maxLength="30"
            placeholder="Пароль"
            className={classNames({ "p-error": isFormFieldValid("password") })}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("password")}
        </div>
        <div className={styles["modal-window_row"] + " margin-bottom--25"}>
          <input
            type="password"
            maxLength="30"
            placeholder="Повторите пароль"
            className={classNames({
              "p-error": isFormFieldValid("confirmPassword"),
            })}
            name="confirmPassword"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("confirmPassword")}
        </div>
        <div className={styles["modal-window_row"]}>
          <label
            className={classNames({
              "p-error": isFormFieldValid("acceptOne"),
              "checkbox-label": true,
            })}
          >
            <input
              className="checkbox-label__control"
              type="checkbox"
              name="acceptOne"
              value={formik.values.acceptOne}
              onChange={formik.handleChange}
            />
            <span className="checkbox-label__inner">
              <span className="checkbox-label__inner-icon"></span>
              <span
                className={classNames({
                  "checkbox-label__inner-text": true,
                  "font-size--13": true,
                  "color-light-gray": !isFormFieldValid("acceptOne"),
                  "p-error": isFormFieldValid("acceptOne"),
                })}
              >
                Я согласен(cна) на обработку моих персональных данных. Я
                подтверждаю что прочитал(а){" "}
                <Link to="/terms">пользовательское соглашение</Link> и{" "}
                <Link to="/privacy">политику конфиденциальности</Link>.
              </span>
            </span>
          </label>
        </div>
        <div className={styles["modal-window_row"]}>
          <label
            className={classNames({
              "p-error": isFormFieldValid("acceptTwo"),
              "checkbox-label": true,
            })}
          >
            <input
              className="checkbox-label__control"
              type="checkbox"
              name="acceptTwo"
              value={formik.values.acceptTwo}
              onChange={formik.handleChange}
            />
            <span className="checkbox-label__inner">
              <span className="checkbox-label__inner-icon"></span>
              <span
                className={classNames({
                  "checkbox-label__inner-text": true,
                  "font-size--13": true,
                  "color-light-gray": !isFormFieldValid("acceptTwo"),
                  "p-error": isFormFieldValid("acceptTwo"),
                })}
              >
                Я согласен(сна) получать информацию об акциях и новостях по
                email
              </span>
            </span>
          </label>
        </div>
        <div
          className={
            styles["modal-window_row"] +
            " " +
            styles["modal-window_row--center"]
          }
        >
          <Captcha
            siteKey={process.env.REACT_APP_CAPTCHA_KEY}
            onResponse={showResponse}
            onExpire={resetCaptcha}
            language="ru"
          />
          {getFormErrorMessage("captcha")}
        </div>
        <div
          className={
            styles["modal-window_row"] +
            " " +
            styles["modal-window_row--center"]
          }
        >
          <div className={styles["modal-window_col"]}>
            {authError}
            <button type="submit" className="app-button">
              Зарегистрироваться
            </button>
          </div>
        </div>
      </form>
    </div>
  );
});

export { SignUp };
