import { ProgressSpinner } from "primereact/progressspinner";

const Progress = ({ visible }) => {
  if (!visible) return "";

  return (
    <div className="progress-spinner">
      <ProgressSpinner
        style={{ width: "70px", height: "70px" }}
        strokeWidth="6"
      />
      <span>Идет загрузка</span>
    </div>
  );
};

export default Progress;
