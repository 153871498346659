import styles from "../autorization.module.css";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { useApp } from "../../../hook/useApp";
import { observer } from "mobx-react-lite";

import { Captcha } from "primereact/captcha";

const SignIn = observer(({ visible, handlerClose }) => {
  const { store } = useApp();

  const authError = store.Auth.authError ? (
    <p className="p-error">{store.Auth.authError}</p>
  ) : (
    ""
  );

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      captcha: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.email) errors.email = "E-mail обязателен для заполнения";
      if (!data.password) errors.password = "Пароль обязателен для заполнения";
      if (!data.captcha) errors.captcha = 'Необходимо пройти капчу'

      return errors;
    },
    onSubmit: async (data) => {
      store.Auth.setAuthError("");
      await store.Auth.login(data);
      store.SportEvent.getStatistics();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  function showResponse(response) {
    formik.setFieldValue("captcha", response);
  }
  function resetCaptcha() {
    formik.setFieldValue("captcha", "");
  }

  if (!visible) return "";

  return (
    <div className={styles["modal-window"]}>
      <div
        className={styles["modal-window__close"]}
        onClick={handlerClose}
      ></div>
      <div className={styles["modal-window__title"]}>Вход в личный кабинет</div>
      <div className={styles["modal-window__sub-title"]}>
        Еще не зарегистрированы?{" "}
        <b>
          <Link to="/signup">Регистрация</Link>
        </b>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles["modal-window_row"]}>
          <input
            type="text"
            placeholder="E-mail"
            className={classNames({ "p-error": isFormFieldValid("email") })}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("email")}
        </div>
        <div className={styles["modal-window_row"]}>
          <input
            type="password"
            placeholder="Пароль"
            className={classNames({ "p-error": isFormFieldValid("password") })}
            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("password")}
        </div>
        <div
          className={
            styles["modal-window_row"] +
            " " +
            styles["modal-window_row--right"] +
            " margin-bottom--25"
          }
        >
          <b>
            <Link to="/forgot-password">Забыли пароль?</Link>
          </b>
        </div>
        <div
          className={
            styles["modal-window_row"] +
            " " +
            styles["modal-window_row--center"]
          }
        >
          <Captcha
            siteKey={process.env.REACT_APP_CAPTCHA_KEY}
            onResponse={showResponse}
            onExpire={resetCaptcha}
            language="ru"
          />
          {getFormErrorMessage("captcha")}
        </div>
        <div
          className={
            styles["modal-window_row"] +
            " " +
            styles["modal-window_row--center"]
          }
        >
          <div className={styles["modal-window_col"]}>
            {authError}
            <button type="submit" className="app-button">
              Вход
            </button>
          </div>
        </div>
      </form>
    </div>
  );
});

export { SignIn };
