import Main from "../../../components/layout/main/Main";

export const Promotion = () => {
  return (
    <Main>
      <h1 className="main__title">Акции</h1>
      <p>
        Здесь будет появляться контекст после старта проекта, в котором будут
        показываться те акции, скидки и прочие «вкусняшки» для пользователей.
      </p>
    </Main>
  );
};
