import IntroHero1WEBP from "../../../assets/intro/intro-hero-1.webp";
import IntroHero1WEBP2x from "../../../assets/intro/intro-hero-1@2x.webp";
import IntroHero1JPG from "../../../assets/intro/intro-hero-1.jpg";
import IntroHero1JPG2x from "../../../assets/intro/intro-hero-1@2x.jpg";

const IntroPicture = () => {
  return (
    <picture className="intro__body-picture">
      <source srcSet={IntroHero1WEBP} type="image/webp" />
      <source srcSet={IntroHero1WEBP2x} type="image/webp" />
      <img
        src={IntroHero1JPG}
        srcSet={IntroHero1JPG2x}
        alt="At vel malesuada sed proin. Eu faucibus purus ullamcorper nibh consequat neque in iaculis"
      />
    </picture>
  );
};

export default IntroPicture;
