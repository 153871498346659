import Logo from "../../ui/logo/Logo";
import FooterMenu from "./menu/FooterMenu";
import ButtonWriteToUs from "../../buttons/ButtonWriteToUsFooter";
import FooterSocial from "./social/FooterSocial";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container footer__container">
        <div className="footer__inner">
          <div className="footer__col footer__col--logo">
            <Logo dark={true} />
          </div>
          <div className="footer__col footer__col--nav">
            <FooterMenu />
          </div>
          <div className="footer__col footer__col--contacts">
            <ButtonWriteToUs>Написать нам</ButtonWriteToUs>
            <FooterSocial />
          </div>
        </div>
        <div className="footer__copyright">
          <p>
            <Link to="/privacy">Политика приватности</Link> и{" "}
            <Link to="/terms">Пользовательское соглашение</Link>
            <br />
            Workbet не является букмекером, не занимается организацией азартных
            игр и не пропагандирует их.
            <br />
            Информация несет ознакомительный характер.
            <br />
            Все права защищены. 18+
          </p>
          <p>Workbet © 2022 - {new Date().getFullYear()}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
