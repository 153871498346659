import { observer } from "mobx-react-lite";
import { useApp } from "../../../../../hook/useApp";
import { formatDate, formatTime } from "../../../../../helpers";

const ProfileCardTable = observer(() => {
  const { store } = useApp();

  const userInfo = store.Auth.infoUser;

  const expirationDate = userInfo?.subscription?.expirationDate;
  const role = userInfo?.subscription?.role;

  return (
    <table className="profile-settings-card__subscription">
      <thead>
        <tr>
          <th>Тариф</th>
          <th>Истекает (UTC)</th>
          <th>Доступные дни заморозки</th>
          <th>Статус</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td data-table-title-mobile="Тариф">{role || "-"}</td>
          <td data-table-title-mobile="Истекает (UTC)">
            {expirationDate
              ? `${formatDate(expirationDate)} ${formatTime(expirationDate)}`
              : role
                ? "Безлимит"
                : "-"}
          </td>
          <td data-table-title-mobile="Доступные дни заморозки">-</td>
          <td data-table-title-mobile="Статус">
            {role ? <div>Активная</div> : "-"}
          </td>
        </tr>
      </tbody>
    </table>
  );
});

export default ProfileCardTable;
