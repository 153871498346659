import Main from "../../../components/layout/main/Main";

export const StartWinning = () => {
  return (
    <Main>
      <h1 className="main__title">Начать выигрывать</h1>
      <p>
        Изначально каждому начинающему «вилочнику» необходимо сделать следующее:
      </p>
      <ul>
        <li>Определиться с размером начального банка.</li>
        <li>Выбрать «рабочие» букмекерские конторы.</li>
        <li>
          Определиться, с каким видом арбитражных ситуаций будет работать
          (prematch или live).
        </li>
        <li>Настроить выдачу сканера вилок.</li>
        <li>Выбрать стратегию игры.</li>
        <li>Выделить время для работы.</li>
      </ul>
      <h2>Определиться с размером начального банка.</h2>
      <p>
        Для успешного старта будет достаточно и 200-300 евро, которые необходимо
        будет равномерно разделить между счетами “рабочих” БК. Впрочем, здесь
        важно понимать, что чем больше будет ваш банк на игру, тем больше
        прибыли вы сможете получить. На первых порах вполне реально зарабатывать
        и 45-50% от банка ежемесячно. Далее этот процент немного уменьшится
        из-за увеличения самого банка.
      </p>
      <h2>Выбрать «рабочие» букмекерские конторы.</h2>
      <p>
        Зарегистрироваться в них, пройти верификацию при необходимости, и
        пополнить деньгами счета.
      </p>
    </Main>
  );
};
