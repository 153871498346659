import Main from "../../../components/layout/main/Main";

export const Identification = () => {
  return (
    <Main>
      <h1 className="main__title">Идентификация (Верификация)</h1>
      <h2>Верификация - зачем нужна и как проходить?</h2>
      <p>
        Верификация игрока в букмекерской конторе — это подтверждение его
        личности, с помощью предоставления официального документа (чаще всего -
        паспорт). Многие новички, которые впервые регистрируются на сайтах
        букмекерских контор, не считают данную процедуру целесообразной и
        безопасной и выбирают альтернативу - создание аккаунта в БК без
        паспорта.{" "}
      </p>
      <p>
        Во всех легальных букмекерских конторах верификация является
        обязательной процедурой для прохождения. Это обусловлено существенным
        снижением рисков, как со стороны игрока, так и со стороны самой конторы.
      </p>
      <h2>Какие способы прохождения верификации существуют?</h2>
      <p>
        На выбор клиента БК предоставлено сразу 3 способа верификации на сайте,
        на выбор:
      </p>
      <ol>
        <li>
          Стандартный способ - отправка сканов паспортных данных (первый
          разворот паспорта и страница с указанием места регистрации).
          Пользователь предварительно регистрируется на сайте. Затем
          авторизуется с помощью полученных данных, переходит в личный кабинет,
          затем в раздел "Настройки". Все сканы должны быть в хорошем качестве,
          иначе служба безопасности может не только отказать в обработке
          запроса, но и вовсе заблокировать доступ к аккаунту из-за подозрения в
          осуществлении мошеннических действий. Прохождение верификации страхует
          букмекера от мошенничества и мультиаккаунтинга, а беттеру дает
          гарантию на честные игры и своевременные выплаты заработанных средств.
        </li>
        <li>
          Альтернативный вариант - если у вас есть аккаунт на портале Госуслуги,
          можно авторизоваться через него, и все персональные данные будут
          автоматически перенаправлены на сайт букмекерской конторы.
        </li>
        <li>
          Третий способ - звонок по видеосвязи через Скайп. Беттеру необходимо
          связаться с сотрудником букмекерской конторы, обозначить удобное время
          и пообщаться с представителем конторы. Во время разговора необходимо
          иметь при себе документ, подтверждающий личность, для предъявления по
          требованию.
        </li>
      </ol>
      <p>
        Если игрок не прошел верификацию, его лимиты на вывод средств будут
        существенно ограничены. В месяц, финансовый оборот не верифицированного
        аккаунта не должен превышать лимит в 200 000 рублей. Размер одной
        транзакции не может превысить порог в 60 000 рублей.
      </p>
      <h2>Безопасность предоставления паспортных данных в БК</h2>
      <p>
        Если речь идет о нелегальных БК, то конечно, предоставление таких данных
        НЕ безопасно!
      </p>
      <p>
        Со стороны оффшорных компаний не существует гарантий, не в плане
        конфиденциальности персональных данных зарегистрированного пользователя,
        ни по поводу платежных реквизитов, не в плане запрета на передачу
        сведений сторонним лицам.
      </p>
      <p>
        В случае возникновения неприятных ситуаций, игроку нелегальной БК будет
        некуда обратиться. представителей компании на территории РФ нет,
        регистрация таких контор находится слишком далеко, чтобы туда обращаться
        и что-то требовать. Да и оснований для требований нет.
      </p>
      <div className="color-pink">
        <p>
          Важно! Безопасное прохождение верификации с предоставлением паспортных
          данных беттера возможно только в том случае, если букмекер
          осуществляет деятельность на территории РФ законно, с разрешения ФНС
          России. Все данные будут храниться в зашифрованном виде, на серверах
          ЦУПИС.
        </p>
      </div>
      <h2>Какие виды спорта поддерживает/будет поддерживать сканер Workbet</h2>
      <ul>
        <li>Футбол </li>
        <li>Баскетбол</li>
        <li>Теннис</li>
        <li>Хоккей</li>
        <li>Волейбол</li>
        <li>Киберспорт</li>
      </ul>
    </Main>
  );
};
