import { observer } from "mobx-react-lite";
import { BackDrop, ModalWindow } from "../ModalWindowComponents";
import { useApp } from "../../../../hook/useApp";
import { Dropdown } from "primereact/dropdown";
import { InputDecimal } from "../../../inputs/InputDecimal";
import { Checkbox } from "primereact/checkbox";
import { InputCheckbox } from "../../../inputs/InputCheckbox";

export const ModalWindowFilter = observer(({ visible }) => {
  let timer;

  const { store } = useApp();

  const dataFilter = store.Filter.dataFilter;
  const currentFilter = store.Filter.currentFilter;

  const isLive = !store.SportEvent.isPrematch;

  const timeEvent = dataFilter.timeEvent.value.value;
  const arrTimeEvent = dataFilter.timeEvent.data;
  async function changeTimeEvent(value) {
    await store.Filter.changeTimeEvent(value);
  }

  const profit = dataFilter.profit.value;
  function changeProfit(e) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      const profit = e.target.value;
      store.Filter.changeProfit(profit);

      clearTimeout(timer);
    }, 300);
  }

  const deleteTimeBets =
    currentFilter.deleteTimeBets || dataFilter.deleteTimeBets.value;

  function changeDeleteTimeBets(e) {
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      const time = e.target.value;
      store.Filter.changeDeleteTimeBets(time);
      clearTimeout(timer);
    }, 300);
  }

  const isFraction = dataFilter.isFraction.value;
  function changeIsFraction(e) {
    store.Filter.changeIsFraction(e.checked);
  }

  const showAsian = dataFilter.showAsian.value;
  function changeShowAsian(e) {
    store.Filter.changeShowAsian(!e.checked);
  }
  const showMain = dataFilter.showMain.value;
  function changeShowMain(e) {
    store.Filter.changeShowMain(e.checked);
  }
  const showHandicap = dataFilter.showHandicap.value;
  function changeShowHandicap(e) {
    store.Filter.changeShowHandicap(e.checked);
  }
  const showTotal = dataFilter.showTotal.value;
  function changeShowTotal(e) {
    store.Filter.changeShowTotal(e.checked);
  }
  const showIndividualTotal = dataFilter.showIndividualTotal.value;
  function changeShowIndividualTotal(e) {
    store.Filter.changeShowIndividualTotal(e.checked);
  }

  const bks = dataFilter.bks.value;
  function changeActiveBk(id) {
    store.Filter.changeBks(id);
  }

  const currentSport = dataFilter.sports.value;
  const sports = dataFilter.sports.data.slice(1);
  function changeFilterSport(label) {
    store.Filter.changeActiveFilterSport(label);
  }

  function handlerClose(e) {
    const clickClass = e.target.className;

    if (
      !clickClass.includes("backdrop") &&
      !clickClass.includes("modal-window__close")
    )
      return false;

    store.Filter.toggleModalWindow(false);

    document.querySelector("body, html").style.overflow = null;
  }

  if (!visible) return "";
  return (
    <BackDrop handlerClose={handlerClose}>
      <ModalWindow title="Фильтр" handlerClose={handlerClose}>
        <div className="modal-window__row">
          <div className="modal-window__column">
            <div className="modal-window_frame">
              <div className="modal-window_frame__title">Профит {"(>=)"}</div>
              <InputDecimal
                className="app-input p-input--theme-white-accent"
                placeholder="Профит"
                onChange={changeProfit}
                defaultValue={profit > 0 ? profit : ""}
              />
            </div>
            {isLive ? (
              ""
            ) : (
              <div className="modal-window_frame">
                <div className="modal-window_frame__title">
                  Время до начала события
                </div>
                <Dropdown
                  className="p-dropdown--theme-white-accent"
                  value={timeEvent}
                  options={arrTimeEvent}
                  onChange={(e) => changeTimeEvent(e.value)}
                />
              </div>
            )}
            <div className="modal-window_frame">
              <div className="modal-window_frame__title">
                Время до удаления вилки
              </div>
              <InputDecimal
                className="app-input p-input--theme-white-accent"
                placeholder="Количество секунд"
                onChange={changeDeleteTimeBets}
                defaultValue={deleteTimeBets > 0 ? deleteTimeBets : ""}
              />
            </div>
          </div>
          <div className="modal-window__column">
            <div className="modal-window_frame">
              <div className="modal-window_frame__title">
                Дополнительные настройки
              </div>
              <div className="field-checkbox display--flex row-horizontal--center">
                <InputCheckbox
                  id="fraction"
                  checked={isFraction}
                  onChange={changeIsFraction}
                >
                  скрыть целые
                </InputCheckbox>
              </div>
              <div className="field-checkbox display--flex row-horizontal--center">
                <InputCheckbox
                  id="asian"
                  checked={!showAsian}
                  onChange={changeShowAsian}
                >
                  скрыть азиатские
                </InputCheckbox>
              </div>
              <div className="field-checkbox display--flex row-horizontal--center">
                <InputCheckbox
                  id="main"
                  checked={showMain}
                  onChange={changeShowMain}
                >
                  основные
                </InputCheckbox>
              </div>
              <div className="field-checkbox display--flex row-horizontal--center">
                <InputCheckbox
                  id="handicap"
                  checked={showHandicap}
                  onChange={changeShowHandicap}
                >
                  гандикапы
                </InputCheckbox>
              </div>
              <div className="field-checkbox display--flex row-horizontal--center">
                <InputCheckbox
                  id="total"
                  checked={showTotal}
                  onChange={changeShowTotal}
                >
                  тоталы
                </InputCheckbox>
              </div>
              <div className="field-checkbox display--flex row-horizontal--center">
                <InputCheckbox
                  id="individualTotal"
                  checked={showIndividualTotal}
                  onChange={changeShowIndividualTotal}
                >
                  инд. тоталы
                </InputCheckbox>
              </div>
            </div>
          </div>
          <div className="modal-window__column">
            <div className="modal-window_frame">
              <div className="modal-window_frame__title">Букмекеры</div>
              {bks.map((item) => {
                return (
                  <div className="popup__col" key={item.id}>
                    <Checkbox
                      inputId={item.bkNameSystem}
                      checked={item.checked}
                      onChange={() => changeActiveBk(item.id)}
                    />
                    <label
                      htmlFor={item.bkNameSystem}
                      className="p-checkbox-label"
                    >
                      {item.bkNameRus}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          {currentSport !== "Топ" ? (
            ""
          ) : (
            <div className="modal-window__column">
              <div className="modal-window_frame">
                <div className="modal-window_frame__title">Виды спорта</div>
                {sports.map((item) => {
                  return (
                    <div className="popup__col" key={item.type}>
                      <Checkbox
                        inputId={item.type}
                        checked={item.active}
                        onChange={() => changeFilterSport(item.label)}
                      />
                      <label htmlFor={item.type} className="p-checkbox-label">
                        {item.label}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </ModalWindow>
    </BackDrop>
  );
});
