import { IconAction } from "../ui/icons/Icons";
import { observer } from "mobx-react-lite";

const ButtonIntroClose = observer(({ store }) => {
  function handlerClick() {
    store.UIInterface.switchStateIntro();
  }

  return (
    <button className="intro__body-close" type="button" onClick={handlerClick}>
      <IconAction name="close" />
    </button>
  );
});

export default ButtonIntroClose;
