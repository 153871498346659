import React from "react";
import { createRoot } from "react-dom/client";
import "./styles/normalize.css";
import "./styles/styles.css";
import App from "./App";
import { Notification } from "./components/notification/Notification";
import reportWebVitals from "./reportWebVitals";

import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "./hoc/AppProvider";
import { ScrollTop } from "./components/scroll/ScrollTop";
import { ModalWriteToUse } from "./components/layout/modalWindow/WriteToUse/ModalWriteToUse";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <ScrollTop />
        <App />
        <Notification position="top-center" />
        <ModalWriteToUse />
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
