import SockJS from "sockjs-client";
import { useApp } from "../hook/useApp";

let socket = null;
const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export function useSocket() {
  const { store } = useApp();

  const onSocketClose = () => {
    if (socket) socket.close();
  };
  const onSocketOpen = (query) => {
    if (socket) {
      onSocketClose();
    }

    socket = new SockJS(`${REACT_APP_BACKEND_URL}sock-bets${query}`);

    socket.onopen = () => {
      console.log("Socket connected");
    };

    socket.onmessage = (e) => {
      const message = JSON.parse(e.data);

      if ("status" in message) {
        store.Auth.logout();
        store.UIInterface.setLogoutMessage();
      }

      if ("type" in message) {
        store.SportEvent.updateArrayEvents(message);
      }
    };

    socket.onclose = () => {
      console.log("Socket disconnected");
    };
  };

  return {
    onSocketClose,
    onSocketOpen,
  };
}
