import styles from "./autorization.module.css";

const Backdrop = ({ children, visible, handlerClose }) => {
  if (!visible) return "";
  return (
    <div className={styles.backdrop} onClick={handlerClose}>
      {children}
    </div>
  );
};

export { Backdrop };
