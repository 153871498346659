export const LIST_BK = {
  FONBET: "fonbet",
  MARATHON: "marathonbet",
  BETCITY: "betcity",
  MELBET: "melbet",
};

export const sortBkInStatRow = {
  [LIST_BK.BETCITY]: 1,
  [LIST_BK.FONBET]: 2,
  [LIST_BK.MARATHON]: 3,
  [LIST_BK.MELBET]: 4,
};
