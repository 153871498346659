import styles from "../autorization.module.css";
import { observer } from "mobx-react-lite";
import { useFormik } from "formik";
import { useApp } from "../../../hook/useApp";
import { classNames } from "primereact/utils";

const Reset = observer(({ visible, handlerClose }) => {
  const { store } = useApp();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.email) errors.email = "E-mail обязателен для заполнения";
      return errors;
    },
    onSubmit: async (data) => {
      await store.Auth.reset(data);
    },
  });

  const authError = store.Auth.authError ? (
    <p className="p-error">{store.Auth.authError}</p>
  ) : (
    ""
  );

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  if (!visible) return "";

  return (
    <div className={styles["modal-window"]}>
      <div
        className={styles["modal-window__close"]}
        onClick={handlerClose}
      ></div>
      <div className={styles["modal-window__title"]}>Восстановление пароля</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles["modal-window_row"]}>
          <input
            type="text"
            placeholder="E-mail"
            className={classNames({ "p-error": isFormFieldValid("email") })}
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {getFormErrorMessage("email")}
        </div>
        <div
          className={
            styles["modal-window_row"] +
            " " +
            styles["modal-window_row--center"]
          }
        >
          <div className={styles["modal-window_col"]}>
            {authError}
            <button type="submit" className="app-button">
              Отправить
            </button>
          </div>
        </div>
      </form>
    </div>
  );
});

export { Reset };
