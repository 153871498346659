import FooterMenuItem from "./FooterMenuItem";

const FooterMenu = () => {
  const menuItems = [
    [
      { id: "footer_1_1", text: "Главная", url: "#" },
      { id: "footer_1_2", text: "Вилки", url: "/forks" },
      { id: "footer_1_3", text: "Особенности", url: "/feature" },
      { id: "footer_1_4", text: "Тарифы", url: "/tariffs" },
      { id: "footer_1_5", text: "Акции", url: "/promotion" },
    ],
    [
      { id: "footer_2_1", text: "Информация", url: "#" },
      { id: "footer_2_2", text: "О нас", url: "/about" },
      { id: "footer_2_3", text: "Правила", url: "/rules" },
      { id: "footer_2_4", text: "Платежи", url: "/payments" },
      { id: "footer_2_5", text: "Возврат средств", url: "/refund" },
    ],
    [
      { id: "footer_3_1", text: "С чего начать", url: "#" },
      { id: "footer_3_2", text: "Начать выигрывать", url: "/start-winning" },
      { id: "footer_3_3", text: "Как сделать ставку", url: "/how-place-bet" },
      { id: "footer_3_4", text: "Как пополнить счет", url: "/how-recharge" },
      {
        id: "footer_3_5",
        text: "Как снять деньги",
        url: "/how-withdraw-money",
      },
      { id: "footer_3_6", text: "Идентификация", url: "/identification" },
    ],
    [
      { id: "footer_4_1", text: "Поддержка", url: "#" },
      { id: "footer_4_2", text: "Юридические запросы", url: "/legal-requests" },
      { id: "footer_4_4", text: "Налоговые запросы", url: "/tax-requests" },
      { id: "footer_4_5", text: "FAQ", url: "/faq" },
    ],
  ];

  const menu = menuItems.map((col) => {
    return (
      <div
        className="footer-nav footer__nav-list-item"
        key={Math.random().toString(32).substring(2, 8)}
      >
        {col.map((item) => {
          return (
            <FooterMenuItem key={item.id} url={item?.url}>
              {item.text}
            </FooterMenuItem>
          );
        })}
      </div>
    );
  });

  return <div className="footer__nav-list">{menu}</div>;
};

export default FooterMenu;
