import SportEvent from "./SportEvent";
import UIInterface from "./UIInterface";
import Auth from "./Auth";
import Filter from "./Filter";
import Payment from "./Payment";

class RootStore {
  constructor() {
    this.Auth = new Auth(this);
    this.SportEvent = new SportEvent(this);
    this.Filter = new Filter(this);
    this.UIInterface = new UIInterface(this);
    this.Payment = new Payment(this);
  }
}

export default RootStore;
