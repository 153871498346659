import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useState } from "react";

const InputCalculator = observer(
  ({ className, value, handler, disabled = false }) => {
    const [myValue, setMyValue] = useState(value || "");

    function handlerFunction(e) {
      const value = e.target.value.replace(",", ".");

      const re = /^\d{0,6}\.{0,1}\d{0,2}$/;

      if (!re.test(value)) return;
      if (value.indexOf(".") === 0) return;
      if (value === "00") return;

      setMyValue(value);

      handler(value);
    }

    useEffect(() => {
      setMyValue(value);
    }, [value]);

    return (
      <input
        type="text"
        className={className}
        value={myValue}
        onChange={handlerFunction}
        disabled={disabled}
      />
    );
  },
);

export default InputCalculator;
