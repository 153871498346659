const ButtonIntroMore = (props) => {
  return (
    <a
      href={props.url}
      className={`${props.visible ? "md-none" : "md-visible"} btn btn--size-lg btn--theme-dark intro__body-content-btn`}
    >
      {props.text}
    </a>
  );
};

export default ButtonIntroMore;
